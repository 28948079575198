import React, { useState } from 'react';
import './Grid.css';
import LineChart from '../../charts/LineChart';
import BarChart from '../../charts/BarChart';
import DonutChart from '../../charts/DonutChart';
import "../../fonts/DidactGothic-Regular.ttf";
import VerticalNavbar from '../Navbar/VerticalNavBar/VerticalNavBar';
import { useData } from '../CSVUploader/DataContext';
import MapVisualization from '../../charts/MapVisualization';
import DataRetrieval from '../DataRetrieval/DataRetrieval';
import RoseChart from '../../charts/RoseChart';
import { csv } from 'd3';



const Grid = () => {

    const { csvData } = useData();
    const [chartData, setChartData] = useState([]);
    const columnsToShow = ["Speed [kn]", 'Distance to all ships', 'Heading', 'Course', 'Main Rudder Angle', 'Main Propeller Revolutions [rpm]'];

    function getColumnData(csvData, partialHeaderName) {
        if (!csvData || csvData.length === 0) return [];

        // Find a header that contains the partialHeaderName
        const headerName = Object.keys(csvData[0]).find(key => key.includes(partialHeaderName));

        if (!headerName) return [];

        //console.log("CSVDATAMAP : " + csvData.map(row => row[headerName]));
        return csvData.map(row => row[headerName]);
    }




    return (
        <div className="layout-container" >
            <VerticalNavbar />
            <div className="content-area">
                <div className="top-div">
                </div>
                <DataRetrieval setChartData={setChartData} columnsToShow={columnsToShow} />
                <div className="grid">
                    <div>
                        <div className="cell">
                            <LineChart
                                dataSets={chartData["Speed [kn]"] || []}
                                chartTitle="Speed"
                                xAxisLabel="Time"
                                yAxisLabel="Speed in [kn]" />
                        </div>
                    </div>
                    <div className="cell">
                        <LineChart
                            dataSets={chartData['Course'] || []}
                            chartTitle="Course [º]"
                            xAxisLabel="Time"
                            yAxisLabel="Course [º]" />
                    </div>
                    <div>
                        <div className="cell">
                            <LineChart
                                dataSets={chartData["Distance to all ships"] || []}
                                chartTitle="Distance to all ships"
                                xAxisLabel="Time"
                                yAxisLabel="Distance in [m]" />
                        </div>
                    </div>
                    {/*<div className='cell'><MapVisualization latitudes={latData} longitudes={lonData} /></div>*/}
                    <div className="cell">
                        <LineChart
                            dataSets={chartData["Heading"] || []}
                            chartTitle="Heading in [º]"
                            xAxisLabel="Time"
                            yAxisLabel="Heading in [º]" /></div>
                    <div className="cell">
                        <LineChart
                            dataSets={chartData["Main Propeller Revolutions [rpm]"] || []}
                            chartTitle="Main Propeller Revolutions [rpm]"
                            xAxisLabel="Time"
                            yAxisLabel="[rpm]" /></div>
                    <div className='cell'><LineChart
                        dataSets={chartData["Main Rudder Angle"] || []}
                        chartTitle="Main Rudder Angle [º]"
                        xAxisLabel="Time"
                        yAxisLabel="Angle [º]" /></div>
                    {/*<div className="cell"><BarChart data={listData} chartTitle="List" xAxisLabel='Time' yAxisLabel='List Value' /></div>*/}
                </div>
            </div>
        </div >
    );
};

export default Grid;
