import React, { useState } from 'react';
import axios from 'axios';
import './DataRetrieval.css';

const DataRetrieval = ({ setChartData, columnsToShow }) => {
    // Function to get the current date in YYYY-MM-DD format
    const getCurrentDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();
        return `${year}-${month}-${day}`;
    };

    // State declarations
    const [selectedStudents, setSelectedStudents] = useState(['Student 1']);
    const [selectedScenario, setSelectedScenario] = useState('Scenario 1');
    const [selectedDate, setSelectedDate] = useState(getCurrentDate());
    const scenarioOptions = ["Scenario 1", "Scenario 2", "Scenario 3"];

    // Event handlers for select and input changes
    const handleStudentChange = (event) => {
        const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedStudents(selectedOptions);
    };

    const handleScenarioChange = (event) => {
        setSelectedScenario(event.target.value);
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    // Function to extract specific column data from CSV data
    const getColumnData = (studentData, partialHeaderName) => {
        if (!studentData || studentData.length === 0) return [];

        // Assuming csvData is an array of objects within each student data item
        const allCsvData = studentData.flatMap(item => item.csvData);

        if (allCsvData.length === 0) {
            console.log("No CSV data available.");
            return [];
        }

        // Log to see what headers are present in the nested CSV data
        console.log("Available Headers in CSV Data:", Object.keys(allCsvData[0]));

        const headerName = Object.keys(allCsvData[0]).find(key => key.includes(partialHeaderName));
        if (!headerName) {
            console.log(`No matching header found for ${partialHeaderName} in CSV data.`);
            return [];
        }

        console.log(`Matched Header in CSV Data: ${headerName}`);
        const columnData = allCsvData.map(row => row[headerName]);
        console.log(`Data for ${headerName}:`, columnData);
        return columnData;
    };


    // Function to handle loading data on button click
    const handleLoadData = () => {
        const studentQueryParam = selectedStudents.includes('all') ? 'all' : selectedStudents.join(',');
        const scenarioQueryParam = selectedScenario;
        const dateQueryParam = selectedDate;

        axios.get('https://imaster.cml.fraunhofer.de/api/getData', {
            params: {
                students: studentQueryParam,
                scenario: scenarioQueryParam,
                date: dateQueryParam
            }
        })
            .then(response => {
                //console.log("API Response:", response.data); // Add this line to log the API response

                let newChartData = {};

                const studentList = selectedStudents.includes('all')
                    ? [...new Set(response.data.map(item => item.student))]
                    : selectedStudents;

                studentList.forEach(student => {
                    columnsToShow.forEach(column => {
                        // Ensure that the filter condition matches the data structure
                        const studentData = response.data.filter(item => item.student === student);
                        console.log(`Filtered data for ${student}:`, studentData); // Log to check the filtered data

                        const columnData = getColumnData(studentData, column);
                        console.log(`Column data for ${column}:`, columnData); // Log to check the column data

                        if (!newChartData[column]) {
                            newChartData[column] = [];
                        }

                        newChartData[column].push({
                            data: columnData,
                            label: `${student}`
                        });
                    });
                });

                console.log("New Chart Data:", newChartData); // Final log to check the structure
                setChartData(newChartData);
            })

            .catch(error => {
                console.error('Error fetching data:', error);
                setChartData([]); // Handle error case
            });
    };


    return (
        <div className="data-retrieval-container">
            {/* Existing Student Select */}
            <select
                multiple
                value={selectedStudents}
                onChange={handleStudentChange}
                className="data-retrieval-select"
            >
                <option value="all">All Students</option>
                {[...Array(10)].map((_, i) => (
                    <option key={i} value={`Student ${i + 1}`}>Student {i + 1}</option>
                ))}
            </select>

            {/* Scenario Select */}
            <select
                value={selectedScenario}
                onChange={handleScenarioChange}
                className="data-retrieval-select"
            >
                {scenarioOptions.map((scenario, index) => (
                    <option key={index} value={scenario}>{scenario}</option>
                ))}
            </select>

            {/* Existing Date Input */}
            <input
                type="date"
                value={selectedDate}
                onChange={handleDateChange}
                className="data-retrieval-date-input"
            />

            {/* Existing Load Data Button */}
            <button
                onClick={handleLoadData}
                className="data-retrieval-button"
            >
                Load Data
            </button>
        </div>
    );
};

export default DataRetrieval;
