import React from 'react';
import Papa from 'papaparse';

function CSVUploader({ onDataParsed }) {
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        parseCSV(file);
    };

    const parseCSV = (file) => {
        Papa.parse(file, {
            complete: (result) => {
                console.log('Parsed Results:', result);
                onDataParsed(result.data);
            },
            header: true
        });
    };

    return (
        <div className='csvuploader'>
            <input type="file" onChange={handleFileChange} accept=".csv" />
        </div>
    );
}

export default CSVUploader;
