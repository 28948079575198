import React, { useState, useEffect } from 'react';
import './VerticalNavBar.css';
import logo from "../../../public/iMaster-Logo.png";
import { FaHome, FaChartLine, FaEnvelope, FaUserCog, FaStar } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";

const VerticalNavbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selected, setSelected] = useState(location.pathname);
    const [isHovered, setIsHovered] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const style = {
        color: "white",
        fontSize: "1.5em",
    };

    const handleButtonClick = (path) => {
        navigate(path);
        setSelected(path);
    };

    useEffect(() => {
        // Logic to set the selected based on location.pathname
        setSelected(location.pathname);

        // Logic to adjust margin of the main content based on navbar expansion
        const contentDiv = document.querySelector(".content-area");

        if (isExpanded) {
            contentDiv.style.marginLeft = "150px"; // Expanded width of navbar
        } else {
            contentDiv.style.marginLeft = "80px";  // Collapsed width of navbar
        }
    }, [location.pathname, isExpanded]); // Notice both dependencies are included

    return (
        <div
            className={`navbar-vertical ${isHovered ? 'hovered' : ''}`}
            onMouseEnter={() => {
                setIsHovered(true);
                setIsExpanded(true)
            }}
            onMouseLeave={() => {
                setIsHovered(false); setIsExpanded(false)
            }}

        >
            <div className="logo-container-nav">
                <img className="verticallogo" src={logo} />
            </div>
            <div className='nav-button-group'>
                <button
                    key="/home"
                    className={`navigation-button ${selected === "/home" ? 'selected' : ''}`}
                    onClick={() => handleButtonClick("/home")}>
                    <FaHome style={style} className="icon-button" /> <span>Home</span>
                </button>
                <button
                    key="/reports"
                    className={`navigation-button ${selected === "/reports" ? 'selected' : ''}`}
                    onClick={() => handleButtonClick("/reports")}>
                    <FaStar style={style} className="icon-button" /> <span>Reports</span>
                </button>
                <button
                    key="/charts"
                    className={`navigation-button ${selected === "/charts" ? 'selected' : ''}`}
                    onClick={() => handleButtonClick("/charts")}>
                    <FaChartLine style={style} className="icon-button" /> <span>Charts</span>
                </button>
                <button
                    key="/messages"
                    className={`navigation-button ${selected === "/messages" ? 'selected' : ''}`}
                    onClick={() => handleButtonClick("/messages")}>
                    <FaEnvelope style={style} className="icon-button" /> <span>Messages</span>
                </button>
                <button
                    key="/settings"
                    className={`navigation-button ${selected === "/settings" ? 'selected' : ''}`}
                    onClick={() => handleButtonClick("/settings")}>
                    <FaUserCog style={style} className="icon-button" /> <span>Settings</span>
                </button>
            </div>
        </div>
    );
};

export default VerticalNavbar;