import React from 'react';

class RoseChart extends React.Component {
    generateSVGPaths(data) {
        const histogram = Array.from({ length: 36 }, () => 0);

        data.forEach(item => {
            const bin = Math.floor(item / 10);
            histogram[bin]++;
        });

        const total = histogram.reduce((acc, val) => acc + val, 0);
        const proportions = histogram.map(count => (count / total) * 360);

        const centerX = 150;
        const centerY = 150;
        const radius = 100;

        let currentAngle = 0;
        return proportions.map((angle, index) => {
            const x1 = centerX + radius * Math.cos(Math.PI * currentAngle / 180);
            const y1 = centerY + radius * Math.sin(Math.PI * currentAngle / 180);

            currentAngle += angle;

            const x2 = centerX + radius * Math.cos(Math.PI * currentAngle / 180);
            const y2 = centerY + radius * Math.sin(Math.PI * currentAngle / 180);

            const largeArc = angle > 180 ? 1 : 0;

            return (
                <path
                    d={`M ${centerX} ${centerY} L ${x1} ${y1} A ${radius} ${radius} 0 ${largeArc} 1 ${x2} ${y2} Z`}
                    fill="#8888DD"
                    key={index} // Changed the key to use the index
                />
            );
        });
    }

    render() {
        const { data } = this.props;

        return (
            <svg width="300" height="300">
                {this.generateSVGPaths(data)}
            </svg>
        );
    }
}

export default RoseChart;
