import React from 'react';

const TableComponent = ({ studentData, selectedStudent }) => {
    const getDaysInMonth = (year, month) => {
        const date = new Date(year, month, 1);
        const days = [];
        while (date.getMonth() === month) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return days;
    };

    const currentDate = new Date();
    const daysInMonth = getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());

    const renderUploadInfo = (dateStr) => {
        const uploadData = studentData[dateStr];
        if (!uploadData) {
            return " / ";
        }
        // Check if uploadData is an array and join, else return the string directly
        return Array.isArray(uploadData) ? `Uploads: ${uploadData.join(', ')}` : `Upload: ${uploadData}`;
    };

    return (
        <div>
            <h3>Data Uploads for {selectedStudent}</h3>
            <ul>
                {daysInMonth.map(day => {
                    const dayStr = day.toISOString().split('T')[0];
                    return <li key={dayStr}>{dayStr} - {renderUploadInfo(dayStr)}</li>;
                })}
            </ul>
        </div>
    );
};

export default TableComponent;
