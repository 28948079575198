import React, { useState } from 'react';
import CSVUploaderElement from '../components/CSVUploader/CSVUploaderElement';
import VerticalNavbar from '../components/Navbar/VerticalNavBar/VerticalNavBar';

const Reports = () => {

    return (
        <div className="layout-container">
            <VerticalNavbar />
            <div className='content-area'>
                <h1>Reports</h1>
                <CSVUploaderElement />
            </div>
        </div>

    );
};

export default Reports;