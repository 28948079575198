import VerticalNavbar from '../components/Navbar/VerticalNavBar/VerticalNavBar';

const Settings = () => {

    return (
        <div className="layout-container">
            <VerticalNavbar />
            <div className='content-area'>
                <h1>Settings</h1>
            </div>
        </div>

    );
};

export default Settings;