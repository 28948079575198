import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const Wave = () => {
    const ref = useRef();
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const svg = d3.select(ref.current);
        const numberOfPoints = 100;
        const points = Array.from({ length: numberOfPoints }, (_, i) => {
            const x = (i / (numberOfPoints - 1)) * dimensions.width;
            const y = 50 * Math.sin((i / (numberOfPoints - 1)) * (2 * Math.PI)) + 100;
            return [x, y];
        });

        const area = d3.area()
            .x(d => d[0])
            .y1(d => d[1])
            .y0(dimensions.height);

        svg
            .append('path')
            .datum(points)
            .attr('fill', 'darkblue')
            .attr('d', area);

        const handleResize = () => {
            setDimensions({ width: window.innerWidth, height: window.innerHeight });
            svg.selectAll('*').remove(); // Clear the SVG on window resize
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, [dimensions]);

    return <svg ref={ref} width={dimensions.width} height={dimensions.height}></svg>;
};

export default Wave;
