import React from 'react';
import Wave from '../Wave';
import LoginForm from '../components/LoginForm/LoginForm';
import AnimatedCircle from '../AnimatedCircle';
import Header from '../Header';

const Login = () => {
    return (
        <div className='loginsite'>
            <Header />
            <AnimatedCircle />
            <LoginForm />
            <Wave />
        </div>
    );
};

export default Login;