import React, { useState, useEffect } from 'react';
import { useData } from './DataContext';
import CSVUploader from './CSVUploader';
import axios from 'axios';
import './CSVUploaderElement.css';
import TableComponent from '../TableComponent/TableComponent';

function CSVUploaderElement() {

    const getCurrentDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const { csvData, setCsvData } = useData();
    const [selectedStudent, setSelectedStudent] = useState('Student 1');
    const [selectedScenario, setSelectedScenario] = useState('Scenario 1');
    const [selectedDate, setSelectedDate] = useState(getCurrentDate());
    const [studentData, setStudentData] = useState({});

    // Function to fetch data
    const fetchData = () => {
        axios.get('https://imaster.cml.fraunhofer.de/api/getData', {
            params: {
                students: selectedStudent
                // Removed scenario and date parameters
            }
        })
            .then(response => {
                processStudentData(response.data);
            })
            .catch(error => console.error('Error:', error));
    };

    // useEffect to fetch data when the component mounts or selectedStudent changes
    useEffect(() => {
        fetchData();
    }, [selectedStudent]); // Depend only on selectedStudent

    // Function to process the fetched data
    const processStudentData = (data) => {
        let processedData = {};
        data.forEach(item => {
            const date = new Date(item.date).toISOString().split('T')[0];
            processedData[date] = item.scenario; // Update to store only the scenario
        });
        setStudentData(processedData);
        console.log(`Processed Data:`, processedData); // Check the processed data
    };

    const handleStudentChange = (event) => {
        setSelectedStudent(event.target.value);
    };

    const handleScenarioChange = (event) => {
        setSelectedScenario(event.target.value);
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handleSubmit = () => {
        const dataToSubmit = {
            student: selectedStudent,
            scenario: selectedScenario,
            date: selectedDate,
            csvData: csvData,
        };

        axios.post('https://imaster.cml.fraunhofer.de/api/saveData', dataToSubmit)
            .then(response => console.log(response.data))
            .catch(error => console.error('Error:', error));
    };

    return (
        <div className="csv-uploader-container">
            <CSVUploader onDataParsed={setCsvData} />
            <div>
                <select onChange={handleStudentChange} value={selectedStudent} className="csv-uploader-select">
                    {[...Array(10)].map((_, i) => <option key={i}>Student {i + 1}</option>)}
                </select>
                <select onChange={handleScenarioChange} value={selectedScenario} className="csv-uploader-select">
                    {[1, 2, 3].map(scenario => <option key={scenario}>Scenario {scenario}</option>)}
                </select>
                <input type="date" onChange={handleDateChange} value={selectedDate} className="csv-uploader-date-input" />
                <button onClick={handleSubmit} className="csv-uploader-button">Submit Data</button>
            </div>
            <TableComponent studentData={studentData} selectedStudent={selectedStudent} />
        </div>
    );
}

export default CSVUploaderElement;
