import React from 'react';
import logo from "./public/iMaster-Logo.png";


function Header() {
    // Import result is the URL of your image
    return (
        <>
            <div className="logo-container">
                <img src={logo} alt="Logo" />;
            </div >
            <div className="headline">
                <h2>Intelligent Learning System</h2>
            </div>
        </>
    )
}

export default Header;