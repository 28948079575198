import React, { useState } from 'react';
import Grid from '../components/Grid/Grid';

const Charts = () => {

    return (
        <div>
            <Grid />
        </div>

    );
};

export default Charts;