import React, { useState } from 'react';
import VerticalNavbar from '../components/Navbar/VerticalNavBar/VerticalNavBar';

const Messages = () => {

    return (
        <div className="layout-container">
            <VerticalNavbar />
            <div className='content-area'>
                <h1>Messages</h1>
            </div>
        </div>

    );
};

export default Messages;