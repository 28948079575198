import React, { createContext, useState, useContext } from 'react';

const DataContext = createContext();

export const useData = () => {
    return useContext(DataContext);
};

export const DataProvider = ({ children }) => {
    const [csvData, setCsvData] = useState([]);

    const store = {
        csvData,
        setCsvData,
    };

    return <DataContext.Provider value={store}>{children}</DataContext.Provider>;
};
