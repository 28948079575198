import React from 'react';
import { BrowserRouter as Router, Routes, Route }
  from 'react-router-dom';
import Home from './pages/home';
import Login from './pages/login';
import Reports from './pages/reports';
import Charts from './pages/charts';
import Messages from './pages/messages';
import Settings from './pages/settings';
import { DataProvider } from './components/CSVUploader/DataContext';

function App() {
  return (
    <DataProvider><Router>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/home' element={<Home />} />
        <Route path='/reports' element={<Reports />} />
        <Route path='/charts' element={<Charts />} />
        <Route path='/messages' element={<Messages />} />
        <Route path='/settings' element={<Settings />} />
      </Routes>
    </Router></DataProvider>

  );
}

export default App;