import React, { useState } from 'react';
import VerticalNavbar from '../components/Navbar/VerticalNavBar/VerticalNavBar';

const Home = () => {

    return (
        <div className="layout-container">
            <VerticalNavbar />
            <div className='content-area'>
                <h1>Home</h1>
                <div className="blocktext">
                    While all educational sectors have been affected by the COVID-19 pandemic, the vocational-oriented academic training components in higher education sectors have been more severely affected than others. For undergraduate and graduate students currently studying for a career at sea, the pandemic has led to suspension to their simulator training sessions, vocational learning opportunities and future careers. The simulator-based education and training domain, which forms the platform of skilled manpower supply for the maritime industry, is facing this unprecedented challenge to ensure the continuity of the educational activities and to cope with the constraints imposed by the pandemic. High-quality vocational education is the cornerstone of effective youth transitions into the labour market for the European society. The suspension of simulator-based training and tutoring sessions has led to concerns regarding whether the higher
                    and vocational educational institutes can produce a sufficient and competent workforce who could adequately carry out on-board navigation functions safely and efficiently in the near future. By accounting for the needs, knowledge gaps and challenges faced by today’s maritime education and training sector, the i-MASTER project aims to integrate emerging technologies in vocational education and training to develop an innovative Intelligent Learning System (ILS) with maritime learning analytics and adaptive learning function to facilitate both remote and on-site maritime simulatorbased education and training. The i-MASTER solution will significantly enhance the effectiveness and accessibility of simulator-based education in the European society and further improve safety, security and performance of maritime operations of the future.
                </div>
            </div>
        </div>

    );
};

export default Home;