import React from 'react';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        const { username, password } = event.target.elements;

        try {

            const response = await fetch('https://imaster.cml.fraunhofer.de/api/authenticate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: username.value,
                    password: password.value,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                // Assuming the response includes a token or some indication of successful authentication
                console.log('Authentication successful:', data);

                // Optionally save the authentication token in localStorage/sessionStorage
                // localStorage.setItem('token', data.token);

                // Navigate to the home page or dashboard
                navigate("/home");
            } else {
                // Handle errors, such as displaying a login failure message
                console.error('Authentication failed');
                alert('Authentication failed. Please check your username and password.');
            }
        } catch (error) {
            // Handle network errors
            console.error('Network error:', error);
            alert('Network error. Please try again later.');
        }
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit} className="login-form">
                <input type="text" id="username" name="username" placeholder="Username" required />
                <input type="password" id="password" name="password" placeholder="Password" required />
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default LoginForm;
