import React, { useEffect, useRef, useState } from "react";

const Circle = ({ delay = 0, xPos = 0, yPos = 0, radius = 20, color = "blue" }) => {
    const circleRef = useRef(null);

    useEffect(() => {
        const startTime = Date.now();

        const animate = () => {
            if (circleRef.current) {
                const time = Date.now() - startTime;
                const newY = yPos + Math.sin((time + delay) * 0.003) * 25; // Reduced amplitude to 25 for less vertical motion
                circleRef.current.setAttribute("cy", newY);
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);

        return () => {
            circleRef.current = null;
        };
    }, [delay, yPos]);

    return <circle ref={circleRef} cx={xPos} cy={yPos} r={radius} fill={color} />;
};

const CircleRow = ({ yPos, radius, color }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const circleSpacing = 3 * radius; // The desired spacing between circles
    const circles = Array.from(
        { length: Math.floor(windowWidth + 100 / circleSpacing) },
        (_, i) => i * circleSpacing
    );

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            {circles.map((xPos, i) => (
                <Circle
                    key={i}
                    delay={i * 100}
                    xPos={xPos}
                    yPos={yPos}
                    radius={radius}
                    color={color}
                />
            ))}
        </>
    );
};

const AnimatedCircles = () => {
    const rows = [
        { yPos: 120, radius: 15, color: "darkblue" },
        { yPos: 90, radius: 20, color: "MediumBlue" },
        { yPos: 60, radius: 25, color: "#007bff" },
        // Add more rows as needed
    ];

    return (
        <svg className="wave" width="100%" height="160">
            {rows.map((row, i) => (
                <CircleRow key={i} {...row} />
            ))}
        </svg>
    );
};

export default AnimatedCircles;
