import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const LineChart = ({
    dataSets,
    chartTitle = "ChartTitle",
    xAxisLabel = "X Axis Label",
    yAxisLabel = "Y Axis Label"
}) => {
    const ref = useRef();

    // Array of 15 colors to cycle through when drawing on the same graph
    const colors = [
        "steelblue",  // A cool blue with a grayish tone
        "darkorange", // A vivid, deep orange
        "forestgreen", // A dark, rich green
        "mediumpurple", // A balanced, not too bright purple
        "crimson", // A deep, vivid red
        "goldenrod", // A dark yellow with a hint of orange
        "teal", // A dark cyan-green color
        "slategray", // A gray with a hint of blue
        "orchid", // A bright, purplish-pink
        "pink" // A muted blue-green
    ];

    const convertToNumbers = (data) => {
        return data.map(d => {
            const parsed = parseFloat(d);
            return isNaN(parsed) ? 0 : parsed; // Replace NaN with 0 or some default value
        });
    };


    useEffect(() => {
        console.log("DataSets before processing:", dataSets);
        const allData = dataSets.map(dataset => {
            if (!Array.isArray(dataset.data)) {
                console.error("Invalid data array:", dataset.data);
                return [];
            }
            return convertToNumbers(dataset.data);
        }).flat();
        console.log("All transformed data for chart:", allData); // Debugging line
        if (dataSets.some(dataset => !Array.isArray(dataset.data))) {
            console.error("Invalid dataset found", dataSets);
            return; // Exit if any dataset is invalid
        }
        console.log("DataSets passed to LineChart:", dataSets); // Debugging line
        const svg = d3.select(ref.current);
        svg.selectAll("*").remove(); // Clear previous chart

        const maxLength = d3.max(dataSets.map(dataset => dataset.data.length));

        const width = 650;
        const height = 250;
        const margin = { top: 30, right: 150, bottom: 30, left: 50 };

        const xScale = d3.scaleLinear()
            .domain([0, maxLength - 1])
            .range([margin.left, width - margin.right]);

        const maxDataValue = d3.max(allData);
        const minDataValue = d3.min(allData);
        const maxDataValueWithPadding = maxDataValue * 1.1;

        const yScale = d3.scaleLinear()
            .domain([minDataValue, maxDataValueWithPadding])
            .range([height - margin.bottom, margin.top]);

        dataSets.forEach((dataset, index) => {
            const numericData = convertToNumbers(dataset.data);

            // Assign a color from the colors array, cycling if necessary
            const lineColor = dataset.color || colors[index % colors.length];
            const lineStyle = dataset.style || "0";

            const line = d3.line()
                .x((d, i) => xScale(i))
                .y(d => yScale(d));

            svg.append("path")
                .datum(numericData)
                .attr("fill", "none")
                .attr("stroke", lineColor)
                .attr("stroke-width", 2.5)
                .attr("d", line)
                .attr("stroke-dasharray", lineStyle);
        });

        const xAxis = d3.axisBottom(xScale);
        const yAxis = d3.axisLeft(yScale);

        svg.append("g")
            .attr("transform", `translate(0, ${height - margin.bottom})`)
            .call(xAxis);

        svg.append("g")
            .attr("transform", `translate(${margin.left}, 0)`)
            .call(yAxis);

        svg.append("text")
            .attr("transform", `translate(${width / 2}, ${height})`)
            .style("text-anchor", "middle")
            .style("font-family", "Arial")
            .style("font-size", "12px")
            .text(xAxisLabel);

        svg.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0)
            .attr("x", 0 - (height / 2))
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .style("font-family", "Arial")
            .style("font-size", "12px")
            .text(yAxisLabel);

        svg.append("text")
            .attr("x", width / 2)
            .attr("y", margin.top / 2)
            .attr("text-anchor", "middle")
            .style("font-family", "Arial")
            .style("font-size", "16px")
            .text(chartTitle);

        if (dataSets.length > 1) {
            const legendX = width - margin.right + 20;
            const legendY = margin.top;
            const legendSpacing = 20;

            dataSets.forEach((dataset, index) => {
                const legendColor = dataset.color || colors[index % colors.length];

                svg.append("rect")
                    .attr("x", legendX)
                    .attr("y", legendY + index * legendSpacing)
                    .attr("width", 10)
                    .attr("height", 10)
                    .style("fill", legendColor);

                svg.append("text")
                    .attr("x", legendX + 15)
                    .attr("y", legendY + index * legendSpacing + 9)
                    .style("font-family", "Arial")
                    .style("font-size", "12px")
                    .text(dataset.label || "");
            });
        }

        // Mouseover functionality
        const bisect = d3.bisector((d) => d).left;

        const focus = svg
            .append('circle')
            .style("fill", "none")
            .attr("stroke", "black")
            .attr('r', 8.5)
            .style("opacity", 0);

        const focusText = svg
            .append('text')
            .style("opacity", 0)
            .attr("text-anchor", "left")
            .attr("alignment-baseline", "middle");

        svg.append('rect')
            .style("fill", "none")
            .style("pointer-events", "all")
            .attr('width', width)
            .attr('height', height)
            .on('mouseover', mouseover)
            .on('mousemove', mousemove)
            .on('mouseout', mouseout);

        function mouseover() {
            focus.style("opacity", 1);
            focusText.style("opacity", 1);
        }

        function mousemove(event) {
            const [xPos, yPos] = d3.pointer(event);

            let closestDistance = Number.POSITIVE_INFINITY;
            let closestXIdx = -1;
            let closestDataY = null;

            dataSets.forEach(dataset => {
                const numericData = convertToNumbers(dataset.data);

                numericData.forEach((yValue, xIdx) => {
                    const xScreenPos = xScale(xIdx);
                    const yScreenPos = yScale(yValue);

                    // Compute Euclidean distance
                    const distance = Math.sqrt((xPos - xScreenPos) ** 2 + (yPos - yScreenPos) ** 2);

                    if (distance < closestDistance) {
                        closestDistance = distance;
                        closestXIdx = xIdx;
                        closestDataY = yValue;
                    }
                });
            });

            if (closestDataY !== null && closestXIdx !== -1) {
                focus
                    .attr("cx", xScale(closestXIdx))
                    .attr("cy", yScale(closestDataY));

                focusText
                    .html("value: " + closestDataY.toFixed(2))
                    .attr("x", xScale(closestXIdx) + 15)
                    .attr("y", yScale(closestDataY) - 10);
            }
        }

        function mouseout() {
            focus.style("opacity", 0);
            focusText.style("opacity", 0);
        }
    }, [dataSets, chartTitle, xAxisLabel, yAxisLabel]);

    return <svg ref={ref} width={650} height={250} />;
};

export default LineChart;
